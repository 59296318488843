// Header
// -----------------------------------------------------------------------------
$header-padding-top-mobile: 2rem;

.header {
  padding-top: $header-padding-top-mobile;

  @media (min-width: $breakpoint-notebook) {
    padding-top: 3rem;
    padding-bottom: 3rem;
    @include make-container();
  }
}

.header__container {
  @media (max-width: $breakpoint-notebook - 1px) {
    @include make-container();
  }
}

.header__inner {
  
  @media (min-width: $breakpoint-notebook) {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    position: relative;
  }  
}

.header__user {
  margin-top: -$header-padding-top-mobile;
  text-align: right;

  @media (min-width: $breakpoint-notebook) {
    align-items: center;
    display: flex;
    position: absolute;
    right: 0;    
    top: 1.5rem;
  }
}

.header__user-name {
  margin-right: 1.9rem;
  @include font-size(15, 1);

  @media (max-width: $breakpoint-notebook - 1px) {
    display: none;
  }
}

.header__user-link {
  color: $color-charcoal;
  display: inline-block;
  font-weight: $font-weight-medium;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  @include font-size(12, 1);
}

.header__home-link {
  align-items: flex-end;
  color: $color-blue;
  justify-content: flex-start;
  display: inline-flex;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $color-blue;
  }
}

.header__logo {
  margin-right: 2rem;
  width: 9rem;

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 0.7rem;
    margin-right: 2rem;
    width: 12rem;
  }

  @media (min-width: $breakpoint-desktop) {
    margin-right: 4rem;
    width: 16rem;    
  }  
}

.header__title {
  font-weight: $font-weight-black;
  margin-bottom: 0.4rem;
  @include font-size(38, 1.02);

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 1.8rem;
    @include font-size(56, 1);
  }
}