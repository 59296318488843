/**
 * Constants
 */

// Theme Colors

// Neutral Palette
$color-obsidian: #111212;
$color-charcoal: #3E3F41;
$color-graphite: #646569;
$color-pearl: #F6F6F5;
$color-white: #FFFFFF;

// Digital Colors
$color-blue: #3998B5;
$color-green: #92AE4F;
$color-purple: #b066B3;
$color-yellow: #FFCE2E;
$color-light-blue: #96C0CE;
$color-orange: #FF7548;
$color-navy: #405866;  

// Darkened Digital Colors
$color-blue-8: #2F7F98;
$color-blue-10: #2D7990;
$color-green-14: #6A7E39;
$color-green-16: #647736;
$color-purple-4: #A858AC;
$color-purple-7: #A051A4;

// Lightened Digital Colors
$color-green-plus-3: #9AB45A;
$color-green-plus-15: #B4C784;
$color-green-plus-25: #C9D7A7;
$color-green-plus-35: #dfe7ca;
$color-green-plus-40: #E9EFDC;
$color-yellow-plus-16: #FFE180;
$color-light-blue-plus-18: #D5E6EC;
$color-orange-plus-6: #FF8C66;
$color-orange-plus-20: #FFC1AD;

// Custom colors
$color-mineshaft: #111111;
$color-error-bg: #FFD9CC;
$color-error: #DB3600;
$color-purple-4-hover: #964C99;

// Fonts
// Sailec
$font-sailec:           "Sailec", Arial, sans-serif;

// Fira Sans Extra Condensed
$font-fira-extra-condensed:  "Fira Sans Extra Condensed", sans-serif;

// Typography
$font-size-root: 62.5%;
$font-size-root-px: 10px;
$font-size-base: 18;
$line-height-base: 1.667;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

// Transition effects
$transition-duration-default: 0.2s;
$transition-duration-slow: $transition-duration-default * 2;

// Gutters and breakpoints
$grid-gutter-width: 20px;
$gutter-side-mobile: 20px;
$gutter-side-tablet: 5.86vw;
$gutter-side-desktop: 75px;

$breakpoint-desktop-raw: 1280;
$breakpoint-tablet-raw: 760;
$breakpoint-mobile-landscape-raw: 470;

$breakpoint-mobile-xs: 375px;
$breakpoint-mobile-landscape: $breakpoint-mobile-landscape-raw + 0px;
$breakpoint-tablet: $breakpoint-tablet-raw + 0px;
$breakpoint-notebook: 950px;
$breakpoint-desktop: $breakpoint-desktop-raw + 0px;

$max-site-width: 1280px;
$max-asset-width: 1920px;

// Helper vars
$default-margin: 8rem;
$default-touch-target: 4.4rem;
$default-rte-margin: 32px;
$page-title-margin-mobile: 3rem;
$page-title-margin-desktop: 7rem;
$form-margin: 3.04rem;