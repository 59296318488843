// Images
.ombuassets-image {
  img {
    vertical-align: top;
  }
}

// Videos
.aspect-ratio-box {
  display: block;
  width: 100%;
  position: relative;

  .aspect-prop {
    display: block;
    position: relative;

    & + * {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }

  @media print {
    display: none;
  }  
}

.ombuassets-video {
  figure iframe {
    vertical-align: top;
  }

  @media print {
    &:before {
      content: attr(data-title);
    }
    &:after {
      content: " (" attr(data-url) ")";
    }    
  }
}

.ombuassets-video__transcripts {
  margin-top: 1rem;
}

.ombuassets-video__transcript {
  display: inline-block;
  margin-right: 3rem;
}

// Documents
.ombuassets-document {
  background-image: url('../core/images/document.svg');
  background-size: 32px 44px;
  background-position: top left;
  background-repeat: no-repeat;
  display: inline-block;
  padding-left: 4.8rem;
  text-decoration: none;

  .title {
    color: $color-blue-10;
    display: block;
    font-weight: $font-weight-black;
    margin-bottom: -5px;
    text-decoration: underline;
    @include font-size(18, 1.66667);
  }

    &:hover .title,
    &:focus .title {
      color: $color-charcoal;
    }  

  .type {
    color: $color-charcoal;
    display: block;
    font-weight: $font-weight-regular;
    margin-bottom: -5px;    
    @include font-size(16, 1.5);
  }
}