// Footer
// -----------------------------------------------------------------------------
.footer {
  background-color: $color-pearl;

  .rte {
    @include rte-base();

    strong {
      font-weight: $font-weight-black;
    }

    a {
      color: $color-charcoal;
    }
  }

  @media (min-width: $breakpoint-notebook) {
    padding-top: 4rem;
  }  
}

// Help
.footer__help {
  color: $color-charcoal;
  padding-top: 3rem;
  padding-bottom: 0.8rem;

  @media (min-width: $breakpoint-notebook) {
    padding-top: 0;
  }
}

.footer__help-heading {
  color: $color-charcoal;  
  font-weight: $font-weight-black;
  margin-top: 0;
  margin-bottom: 2.4rem;
  @include font-size(24, 1);

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: -.94rem;
    @include font-size(40, 1);
  }
}

.footer__help-inner {
  @media (min-width: $breakpoint-notebook) {
    display: flex;    
    align-items: flex-start;
  }
}

.footer__urgent-care {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;

  @media (min-width: $breakpoint-notebook) {
    flex: 0 0 auto;
    margin-right: 6rem;    
    margin-bottom: 4rem;
    margin-top: 5.5rem;
    width: 28rem;
  }
}

.footer__emergency {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;

  @media (min-width: $breakpoint-notebook) {
    flex: 0 0 auto;
    margin-bottom: 4rem;   
    margin-top: 5.5rem; 
    width: 28rem;
  }
}

.footer__help-illustration {
  align-self: flex-end;
  flex: 1 1 auto;
  margin-bottom: 1.76rem;
  text-align: right;

  @media (max-width: $breakpoint-notebook - 1px) {
    display: none;
  }
}

.footer__help-illustration-image {
  width: 19.6rem;
}

// Contact
.footer__contact {
  background-color: $color-blue-10;
  color: $color-white;
  margin-top: 3.2rem;
  padding-bottom: 1.74rem;

  @media (min-width: $breakpoint-notebook) {
    margin-top: 4rem;
    padding-bottom: 4rem;
  }

  .rte a {
    color: $color-white;

    &:hover {
      color: $color-white;
    }

    &:focus {
      color: $color-charcoal;
    }
  }
}

.footer__contact-heading {
  margin-top: -3.25rem;
  margin-bottom: 2rem;

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 0;
    margin-top: -4rem;
  }
}

.footer__contact-content {
  margin-bottom: 1.5rem;

  @media (min-width: $breakpoint-notebook) {
    display: flex;
    margin-bottom: 0;
  }
}

.footer__contact-text {
  @media (min-width: $breakpoint-notebook) {
    align-items: flex-start;
    flex: 0 0 auto;
    margin-top: 3rem;
    width: 75.5rem;
  }
}

.footer__contact-illustration {
  flex: 1 1 auto;
  text-align: right;

  @media (max-width: $breakpoint-notebook - 1px) {
    display: none;
  }
}

.footer__contact-illustration-image {
  width: 19.3rem;
}

// Info
.footer__info {
  background-color: $color-navy;
  color: $color-white;
  padding-top: 2.5rem;
  padding-bottom: 3.8rem;

  .rte a {
    color: $color-white;
    white-space: nowrap;

    &:hover {
      color: $color-white;
    }

    &:focus {
      color: $color-charcoal;
    }
  }  

  @media (min-width: $breakpoint-notebook) {
    padding-top: 7.5rem;
    padding-bottom: 7.3rem;    
  }
}

.footer__info-text {

}

.footer__info-primary {
  margin-bottom: 3rem;

  @media (min-width: $breakpoint-notebook) {
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 6rem;
  }
}

.footer__info-home-link {
  display: inline-block;

  &:focus {
    background-color: transparent;
  }
}

.footer__logo {
  width: 6rem;

  @media (min-width: $breakpoint-notebook) {
    margin-right: 7.5rem;
    width: 12.8rem;
  }  
}

.footer__info-links {
  list-style: none;
  margin: -0.64rem 0 1.6rem;
  padding: 0;

  @media (min-width: $breakpoint-notebook) {
    justify-content: flex-start;
    display: flex;
    margin-top: 0;
    margin-bottom: 1.5rem;
  }
}

.footer__info-list-item {
  @media (min-width: $breakpoint-notebook) {
    margin-right: 3rem;
  }
}

.footer__info-link {
  color: $color-white;
  display: inline-block;
  font-weight: $font-weight-medium;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  @include font-size(12, 1);

  &:hover {
    color: $color-white;
  }

  &:focus {
    color: $color-charcoal;
  }
}

.footer__copyright {
  font-weight: $font-weight-medium;
  @include font-size(12, 1);
}