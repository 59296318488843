.form {
  margin-bottom: $default-margin;
  max-width: 100%;
  width: 60rem;
}

.form__group {
  margin-bottom: $form-margin;
}

.form__global-help {
  margin-bottom: $form-margin;
}

.form__label-optional {
  font-weight: $font-weight-regular;
  display: inline-block;
  margin-left: 0.8rem;
  margin-top: 1.7rem;
  text-transform: uppercase;
  @include font-size(14, 1.071428571);
}

.form__help {
  font-weight: $font-weight-regular;
  margin-top: 0.8rem;
  @include font-size(16, 1.6);

  p,ul,ol,h1,h2,h3,h4,h5,h6 {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .form__multi-input-group &,
  .form__label + & {
    margin-top: -0.7rem;
    margin-bottom: 0.9rem;
  }

  .form__errors + & {
    margin-bottom: 0.9rem;
  }
}

.form__help--password-group {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.form__password-show-toggle {
  border: none;
  border-radius: 2px;
  background: none;
  color: $color-charcoal;
  font-weight: $font-weight-bold; 
  padding-left: 0;
  padding-right: 0; 
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  z-index: 2;
  @include font-size(16, 1);
  @include custom-focus();
}

.form__password-show-toggle-help {
  @include element-invisible();
}

.form__password-show-toggle-inner--hide {
  display: none;

  .form__password-show-toggle--active & {
    display: inline;
  }
}

.form__password-show-toggle-inner--show {
  display: inline;

  .form__password-show-toggle--active & {
    display: none;
  }
}

// Multi-input
.form__fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.form__multi-input-label {
  color: $color-mineshaft;
  font-weight: $font-weight-medium;
  @include font-size(18, 1.4);
}

.form__input--multi-input {
  @include element-invisible();
}

$multi-input-focus-offset: 0.7rem;
$multi-input-border-width: 0.1rem;
.form__multi-input-label-inner {
  display: block;
  margin-left: -0.7rem;
  padding: 1rem 0 1rem 5.4rem;
  position: relative;

  &:before {
    border-radius: 2px;
    content: '';
    left: 0;
    height: $default-touch-target;
    position: absolute;
    width: $default-touch-target;
    top: 0;
    @include custom-focus();
  }

  &:after {
    background-color: $color-pearl;
    background-repeat: no-repeat;
    background-position: center center;
    border: $multi-input-border-width solid $color-charcoal;
    border-radius: 2px;
    content: '';
    height: $default-touch-target - ($multi-input-focus-offset * 2);
    left: $multi-input-focus-offset - $multi-input-border-width;
    position: absolute;
    top: $multi-input-focus-offset - $multi-input-border-width;
    width: $default-touch-target - ($multi-input-focus-offset * 2);
  }

  .form__input--multi-input:checked + label &:after {
    background-image: url('../core/images/checkmark.svg'); 
  }

  .form__input--multi-input:focus + label &:before,
  .form__input--multi-input.focus + label &:before {
    @include custom-focus-inner();
  }

  .form__input--multi-input[aria-invalid="true"] ~ label &:after {
    background-color: $color-error-bg;
  }
}

.form__multi-input-label-inner--radio {
  &:before {
    border-radius: 100%;
  }

  &:after {
    border-radius: 100%;
  }

  .form__input--multi-input:checked + label &:after {
    background-image: url('../core/images/radio.svg'); 
  }  
}

// submit
.form__submit {
  @include button-primary();
}

// file
.form__file {
  align-items: center;
  display: flex;
}

.form__file-button {
  background-color: $color-graphite;
  border: 1px solid $color-charcoal;
  border-radius: 2px;
  color: $color-white;
  font-weight: $font-weight-black;
  min-width: 12rem;
  padding: 1rem 1.5rem 0.68rem;
  text-transform: uppercase;
  transition: all $transition-duration-default ease;
  @include font-size(12, 2.1);
  @include custom-form-focus();
}

.form__file-inner {
  cursor: pointer;
  display: inline-block;
  flex: 0 0 auto;
  position: relative;

  input[type="file"] {
    bottom: 0;
    cursor: pointer;
    font-size: 0;
    height: 100%;
    left: 0;
    width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;

    &:hover,
    &.hover {
      & + .form__file-button {
        background-color: $color-charcoal;
      }
    }

    &:focus,
    &.focus {
      & + .form__file-button {
        @include custom-form-focus-inner();
      }
    }
  }
}

.form__file-names {
  color: $color-charcoal;
  font-weight: $font-weight-medium;
  flex: 1 1 auto;
  margin-left: 2rem;
  margin-top: 2px;
  min-width: 0px;
  word-wrap: break-all;
  @include font-size(18, 1.4);

  .form__file--errors & {
    color: $color-error;
  }
}

.form__file-names--empty {
  color: $color-charcoal;
}

.form__file-name {
  word-wrap: break-word;
}

// errors
.form__errors--field {
  color: $color-error;
  font-weight: $font-weight-bold;
  font-style: italic;
  margin-bottom: 1.3rem;
  margin-top: -0.5rem;
  padding: 0.7rem 0 0.34rem 3.7rem;
  position: relative;
  @include font-size(14, 1.4);

  .alerts-banner & {
    color: $color-charcoal;
  }

  .errorlist {
    padding: 0;
    list-style: none;
    margin: 0;
  }   

  svg {
    top: 0;
    left: 0;
    position: absolute;
  }
}

.form__errors-heading {
  margin-bottom: 1.5rem;
  margin-top: 0;
  @include font-size(20, 1.4);

  .errorlist {
    list-style-type: circle;
  }
}