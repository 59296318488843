#admin-overlay {
  a {
    font-weight: $font-weight-regular;
  }
}

.admin-overlay--info-table-value {
  margin-left: 0;
}

.admin-overlay--panels-link {
  &:focus {
    background-color: transparent;
  }
}