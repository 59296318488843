.page-title {
  background-color: $color-navy;
  color: $color-white;
  padding-top: 6.4rem;
  padding-bottom: 6rem;  

  @media (max-width: $breakpoint-desktop) {
    padding-top: 5vw;
    padding-bottom: 4.68vw;
  }
  @media (max-width: $breakpoint-mobile-landscape) {
    padding-top: 2rem;
    padding-bottom: 1.4rem;  
  }  
}

.page-title--home {
  margin-bottom: 0;

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 0;
  }
}

.page-title--with-links {
  padding-top: 5.5rem;

  @media (max-width: $breakpoint-desktop) {
    padding-top: 4.55vw;
  }  

  @media (max-width: $breakpoint-tablet) {
    padding-top: 3.6rem;
  }   
}

h1.page-title__title,
h2.page-title__title {
  color: $color-white;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 79px;

  @media (max-width: $breakpoint-desktop) {
    font-size: calc(30px + (79 - 30) * ((100vw - #{$breakpoint-mobile-landscape}) / (#{$breakpoint-desktop-raw} - #{$breakpoint-mobile-landscape-raw})));
  }
  @media (max-width: $breakpoint-mobile-landscape) {
    font-size: 30px;
  }    
}

.page-title__links {
  margin-top: 3.1rem;
  margin-bottom: -1.4rem;

  @media (min-width: $breakpoint-tablet) {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }

  @media (min-width: $breakpoint-notebook) {
    margin-top: 3.2rem;
  }
}

.page-title__links-heading {
  @include rte-base;

  @media (min-width: $breakpoint-tablet) {
    flex: 0 0 auto;
    margin-right: 2.5rem;
  }

  @media (min-width: $breakpoint-notebook) {
    margin-right: 4rem;
  }  
}

.page-title__list {
  columns: 2;
  margin-top: 0.4rem;
  list-style: none;
  margin-bottom: 2.8rem;
  padding: 0;
  @include rte-base;

  @media (min-width: $breakpoint-tablet) {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    columns: 1;
    column-gap: 0;
    justify-content: flex-start;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.page-title__link {
  color: $color-white;
  font-weight: $font-weight-black;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: inline-block;

  @media (min-width: $breakpoint-notebook) {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;    
  }

  &:hover {
    color: $color-light-blue-plus-18;
  }
}

.page-title__list-item {
  flex: 0 0 auto;
  margin-right: 1.5rem;

  @media (min-width: $breakpoint-notebook) {
    margin-right: 4rem;
  }
}