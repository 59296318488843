.account__primary {
  @media (min-width: $breakpoint-tablet) {
    float: left;
    padding-right: $grid-gutter-width;
    width: 50%;
  }

  @media (min-width: $breakpoint-desktop) {
    padding-right: 4rem;
  }
}

.account__secondary {
  @media (min-width: $breakpoint-tablet) {
    float: left;
    padding-left: $grid-gutter-width;
    width: 50%;
  }

  @media (min-width: $breakpoint-desktop) {
    padding-left: 4rem;
  }  
}

.account__instructions-group {
  margin-bottom: $form-margin;
}

.account__password-reset {
  width: 20rem;
}

.account__password-reset-instructions {
  margin-bottom: 1.5rem;
}

.account__label {
  @include custom-label();
}