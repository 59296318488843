// Header
// -----------------------------------------------------------------------------

.navigation {
  @media (min-width: $breakpoint-notebook) {
    align-items: flex-start;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.7rem;
  }
}

.navigation__primary {
  @media (max-width: $breakpoint-notebook - 1px) {
    background-color: $color-green-plus-3;
  }
}

.navigation__primary-container {
  @media (max-width: $breakpoint-notebook - 1px) {
    @include make-container();
  }
}

.navigation__secondary-container {
  @media (max-width: $breakpoint-notebook - 1px) {
    @include make-container();
  }
}

.navigation__list {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  @media (min-width: $breakpoint-mobile-xs) {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    margin-left: 0;
    margin-right: 0;
  }
  
  @media (min-width: $breakpoint-notebook) {
    padding-left: 0;
    padding-right: 0;
  }

  &.navigation__list--anon {
    @media (max-width: $breakpoint-notebook - 1px) {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
}

.navigation__list-item {

  @media (min-width: $breakpoint-mobile-landscape) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @media (min-width: $breakpoint-notebook) {
    margin-left: 1.5rem;
    margin-right: 0;
  }  

  @media (min-width: $breakpoint-desktop) {
    margin-left: 3rem;
    margin-right: 0;
  }

  &:first-child {
    margin-left: 0;
  }
}

.navigation__link {
  display: block;
  font-weight: $font-weight-bold;
  padding-top: 1.5rem;
  padding-bottom: 1.4rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  @include font-size(15, 1);

  @media (min-width: $breakpoint-notebook) {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    @include font-size(20, 1);
  }
}

span.navigation__link {
  color: $color-mineshaft;
}

a.navigation__link {
  color: $color-charcoal;

  @media (min-width: $breakpoint-notebook) {
    color: $color-blue;
  }
}

.navigation__secondary {
  @media (max-width: $breakpoint-notebook - 1px) {
    background-color: $color-green-plus-25;
  }
}

.navigation__id-card {
  @media (min-width: $breakpoint-notebook) {
    margin-left: 3rem;
    padding-left: 3rem;
    position: relative;

    &:before {
      background-color: rgba(0,0,0,0.2);
      content: "";
      height: 4.4rem;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
    }    
  }
}

.navigation__id-card-link {
  align-items: center;
  color: $color-charcoal;
  display: flex;
  justify-content: flex-start;
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  text-decoration: none;

  @media (min-width: $breakpoint-notebook) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.navigation__id-card-image {
  width: 6rem;

  @media (min-width: $breakpoint-notebook) {
    width: 7rem;
  }
}

.navigation__id-card-text {
  font-weight: $font-weight-bold;
  margin-left: 1.4rem;
  text-decoration: underline;
  white-space: nowrap;
  @include font-size(15, 1);
}

.navigation__id-card-name {
  font-weight: $font-weight-medium;
  margin-left: 1.3rem;
  padding-left: 1.2rem;
  position: relative;
  @include font-size(13, 1);

  &:before {
    background-color: rgba(0,0,0,0.2);
    content: "";
    height: 2.2rem;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
  }

  @media (min-width: $breakpoint-notebook) {
    display: none;
  }
}