// Mixins

@mixin clearfix() {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin font-size($font-size: $font-size-base, $line-height: $line-height-base) {
  font-size: $font-size * ($font-size-root-px / 10);

  // Restore rem font-size rule once Chrome fixes sizing bug.
  // https://code.google.com/p/chromium/issues/detail?id=319623
  font-size: $font-size / 10 + rem;
  line-height: $line-height;
}

@mixin element-invisible {
  position: absolute !important;
  height: 1px; width: 1px; 
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

// custom focus
@mixin custom-focus() {
  box-shadow: 0 0 0 2px transparent,
              0 0 0 3px transparent
              0 0 0 5.5px transparent,
              0 0 0 6.5px transparent;
  transition: all $transition-duration-default ease;

  &:focus,
  &.focus {
    @include custom-focus-inner();
  }

  &:active {
    @include custom-active-inner();
  }
}

@mixin custom-focus-inner($color: #c9e1e8) {
  background-color: $color-white;
  box-shadow: 0 0 0 2px $color-white,
              0 0 0 3px $color,
              0 0 0 5.5px rgba($color-blue, 1),
              0 0 0 6.5px rgba($color-blue, 0.25);
  color: $color-charcoal;
}

@mixin custom-active-inner($color: #c9e1e8) {
  box-shadow: 0 0 0 2px $color-white,
              0 0 0 3px $color,
              0 0 0 9px rgba($color-blue, 1),
              0 0 0 10px rgba($color-blue, 0.25);
}

@mixin custom-form-focus() {
  box-shadow: 0 0 0 2.5px transparent,
              0 0 0 3.5px transparent;
  transition: all $transition-duration-default ease;

  &:focus,
  &.focus {
    @include custom-form-focus-inner();
  }
}

@mixin custom-form-focus-inner($color: $color-blue) {
  box-shadow: 0 0 0 2.5px rgba($color-blue, 1),
              0 0 0 3.5px rgba($color-blue, 0.25);
}

// CTA links

@mixin link-primary() {
  display: inline-block;
  margin: -0.3rem;
  padding: 0.3rem;
  text-decoration: none;
}

@mixin link-primary-inner() {
  background-color: $color-purple-4;
  border: 3px solid transparent;
  color: $color-white;
  display: inline-block;
  font-weight: $font-weight-black;
  padding: 1.2rem 2.2rem;
  transition: all $transition-duration-default ease;

  @supports (clip-path: polygon(0 0, 100% 10.75%, 100% 100%, 0% 100%)) {
    clip-path: polygon(0 0, 100% 10.75%, 100% 100%, 0% 100%);  
    padding-top: 2.2rem;
  }  

  &:focus {
    border-color: $color-blue;
  }

  &:hover {
    background-color: #964c99;
  }
}

@mixin button-primary() {
  background-color: $color-purple-4;
  border: none;
  color: $color-white;
  display: inline-block;
  font-weight: $font-weight-black;
  max-width: 28rem;
  padding: 1rem 2rem;
  position: relative;
  text-transform: uppercase;  
  transition: background-color $transition-duration-default ease;
  @include font-size(15, 1.4);

  @supports (clip-path: polygon(0 0, 100% 5px, 100% 100%, 0% 100%)) {
    margin-top: 0.5rem; 

    &:after {
      background-color: $color-purple-4;
      clip-path: polygon(0 0, 100% 5px, 100% 100%, 0% 100%);
      content: '';       
      position: absolute;
      left: 0;
      display: block;
      top: -5px;
      width: 100%;
      height: 0.6rem;
      transition: background-color $transition-duration-default ease;
    }     
  }

  &:before {
    bottom: -0.2rem;
    content: "";
    border-radius: 2px;
    left: -0.2rem;
    right: -0.2rem;
    top: -0.7rem;
    position: absolute;
  }  

  &:focus,
  &.focus {

    &:before {
      @include custom-focus-inner();
      background-color: transparent;
    }
  } 

  &:hover,
  &.hover {
    background-color: $color-purple-4-hover;

    &:after {
      background-color: $color-purple-4-hover;
    }
  }   
}

@mixin custom-label {
  color: $color-charcoal;
  display: block;
  font-weight: $font-weight-bold;
  margin-bottom: 1rem;
  margin-top: 0;
  @include font-size(18, 1.7778);
}

// layout
@mixin make-container() {
  padding-left: $gutter-side-desktop;
  padding-right: $gutter-side-desktop;
  margin-left: auto;
  margin-right: auto;
  max-width: $max-site-width;
  width: 100%;

  @media (max-width: $breakpoint-desktop) {
    padding-left: $gutter-side-tablet;
    padding-right: $gutter-side-tablet;
  }

  @media (max-width: $breakpoint-mobile-landscape) {
    padding-left: $gutter-side-mobile;
    padding-right: $gutter-side-mobile;
  } 
}

// RTE
@mixin heading-primary {
  font-size: 79px;
  font-weight: $font-weight-black;
  line-height: 0.95;
  color: $color-purple;
  @media (max-width: $breakpoint-desktop) {
    font-size: calc(41px + (79 - 41) * ((100vw - #{$breakpoint-mobile-landscape}) / (#{$breakpoint-desktop-raw} - #{$breakpoint-mobile-landscape-raw})));
  }
  @media (max-width: $breakpoint-mobile-landscape) {
    font-size: 41px;
  }  
}

@mixin heading-secondary {
  font-size: 40px;
  font-weight: $font-weight-black;
  line-height: 1.125;
  color: $color-purple;

  @media (max-width: $breakpoint-desktop) {
    font-size: calc(22px + (40 - 22) * ((100vw - #{$breakpoint-mobile-landscape}) / (#{$breakpoint-desktop-raw} - #{$breakpoint-mobile-landscape-raw})));
  }
  @media (max-width: $breakpoint-mobile-landscape) {
    font-size: 22px;
    line-height: 1.182; 
  }
}

@mixin heading-tertiary {
  font-size: 24px;
  font-weight: $font-weight-bold;
  color: $color-purple;
  @media (max-width: $breakpoint-desktop) {
    font-size: calc(20px + (24- 20) * ((100vw - #{$breakpoint-mobile-landscape}) / (#{$breakpoint-desktop-raw} - #{$breakpoint-mobile-landscape-raw})));
  }
  @media (max-width: $breakpoint-mobile-landscape) {
    font-size: 20px;
    line-height: 1; 
  }
}

@mixin heading-quaternary {
  font-size: 18px;
  font-weight: $font-weight-black;
  color: $color-purple;
  @media (max-width: $breakpoint-tablet) {
    font-size: calc(16px + (18 - 16) * ((100vw - #{$breakpoint-mobile-landscape}) / (#{$breakpoint-tablet-raw} - #{$breakpoint-mobile-landscape-raw})));
  }
  @media (max-width: $breakpoint-mobile-landscape) {
    font-size: 16px;
  }
}

@mixin heading-quinary {
  font-size: 24px;
  font-weight: $font-weight-bold;
  line-height: 1.25;
  color: $color-purple;
  text-transform: uppercase;
  @media (max-width: $breakpoint-desktop) {
    font-size: calc(16px + (24 - 16) * ((100vw - #{$breakpoint-mobile-landscape}) / (#{$breakpoint-desktop-raw} - #{$breakpoint-mobile-landscape-raw})));
  }
  @media (max-width: $breakpoint-mobile-landscape) {
    font-size: 16px;
    font-weight: $font-weight-medium;
  }
}

@mixin heading-senary {
  font-size: 12.06px;
  font-weight: $font-weight-bold;
  line-height: 1.25;
  color: $color-purple;
  @media (max-width: $breakpoint-desktop) {
    font-size: calc(10.72px + (12.06 - 10.72) * ((100vw - #{$breakpoint-mobile-landscape}) / (#{$breakpoint-desktop-raw} - #{$breakpoint-mobile-landscape-raw})));
  }
  @media (max-width: $breakpoint-mobile-landscape) {
    font-size: 10.72px;
    font-weight: $font-weight-medium;
  }  
}

@mixin rte-base {
  font-size: 18px;
  font-weight: $font-weight-regular;
  line-height: 1.667;
  @media (max-width: $breakpoint-tablet) {
    font-size: calc(16px + (18 - 16) * ((100vw - #{$breakpoint-mobile-landscape}) / (#{$breakpoint-tablet-raw} - #{$breakpoint-mobile-landscape-raw})));
  }
  @media (max-width: $breakpoint-mobile-landscape) {
    font-size: 16px;
    font-weight: $font-weight-light;
    line-height: 1.5;
  }
}

@mixin rte--light() {
  color: $color-white;

  hr,h1,h2,h3,h4,h5,h6,a {
    color: $color-white;
  }

  a:hover {
    color: $color-white;
  }
}