.block {
  margin-bottom: 4rem;
  margin-top: 4rem;

  @media (max-width: $breakpoint-tablet) {
    margin-top: 3rem;
  }
}

.block__title {
  color: $color-purple;
  font-weight: $font-weight-bold;
  margin-top: 0;
  margin-bottom: 3rem;
  @include font-size(40, 1.125);

  .block--callout > & {
    display: none;
  }
}