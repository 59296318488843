.callout {
  display: block;
  margin: -1px;
  padding: 1px;
  text-decoration: none;
  transition: color $transition-duration-default 0.05s ease-in-out;
}

.callout__inner {
  box-shadow: 0 3px 10px 0 rgba(0,0,0,0.1);
}

a.callout:hover,
a.callout:focus {
  background-color: transparent;
  color: $color-white;
  transition-delay: 0.1s;
}

.callout__image-wrapper {
  height: 0;
  padding-top: 57.1428571%;
  position: relative;

  &:after {
    background-color: $color-navy;
    bottom: 0;
    clip-path: polygon(0 0, 105% 8px, 105% 105%, 0% 105%);
    content: "";
    left: 0;
    height: 3.3rem;
    position: absolute;
    width: 92%;
  }
}

.callout__image {
  object-fit: cover;
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.callout__content {
  padding: 2.2rem 2rem 1.8rem;
  position: relative;
  overflow: hidden;

  &:before {
    background-color: $color-navy;
    content: '';    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out;
    transform: translateY(100%);
    z-index: -1;
  }

  a.callout:hover &:before,
  a.callout:focus &:before { 
    transition-delay: 0s;
    transform: translateY(0);
  }
}

.callout__title {
  color: $color-navy;
  font-weight: $font-weight-bold;
  margin-top: 0;
  margin-bottom: 0;
  transition: color $transition-duration-default 0.05s ease-in-out;  
  @include font-size(24, 1);

  a.callout:hover &,
  a.callout:focus & { 
    color: $color-white;
    transition-delay: 0.1s;
  }  
}

.callout__rte {
  * {
    transition: color $transition-duration-default 0.05s ease-in-out;
  }   

  a.callout:hover &,
  a.callout:focus & {
    @include rte--light();
    * {
      transition-delay: 0.1s;
    }    
  }
}

.callout__title + .callout__rte {
  margin-top: 3rem;
}

.callout__title + .callout__link {
  margin-top: 3rem;
}

.callout__rte + .callout__link {
  margin-top: 3rem;
}

.callout__link  {
  color: $color-blue-10;
  display: inline-block;
  font-weight: $font-weight-black;
  text-decoration: underline;
  transition: color $transition-duration-default 0.05s ease-in-out;  
  @include font-size(18, 1.6667);

  svg {
    display: inline-block;
    margin-right: 1rem;

    * {
      stroke: $color-blue-10;
      transition: stroke $transition-duration-default 0.05s ease-in-out;  
    }
  }

  a.callout:hover &,
  a.callout:focus & { 
    color: $color-white;
    transition-delay: 0.1s;

    svg * {
      stroke: $color-white;
      transition-delay: 0.1s;
    }
  }   
}