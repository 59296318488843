.card-banner {
  @media (min-width: $breakpoint-notebook + 1px) {
    display: none;
  }
}

.card-banner--header {
}

.card-banner__links {
  margin-botton: 4px;
  margin-top: 4px;
  text-align: right;
}

.card-banner__link {
  color: $color-charcoal;
  display: inline-block;
  font-weight: $font-weight-medium;
  padding: 1.6rem 0;
  @include font-size(12, 1);
}

.card-banner__inner {
  background-color: $color-green-plus-25;
  display: block;
  padding: 1.8rem 0 1.8rem;

  .card-banner--footer & {
    padding-bottom: 6.4rem;
  }
}

.card-banner__back-link {
  display: block;
  color: $color-charcoal;
  font-weight: $font-weight-bold;
  @include font-size(15, 1.2);
}

.card-banner__back-link-icon {
  display: inline-block;
  font-family: $font-fira-extra-condensed;
  font-weight: $font-weight-light;
  margin-right: 5px;
  position: relative;
  top: -2px;
}