.home__banner {
  background-color: $color-purple-4;
  color: $color-white;
}

.home__banner-inner {
  @media (min-width: $breakpoint-tablet + 1px) {
    display: flex;
    @include make-container();
  }  
}

.home__banner-content {
  align-self: center;
  flex: 0 0 50%;
  padding-bottom: 3.9rem;

  @media (min-width: $breakpoint-tablet + 1px) {
    max-width: 50%;
    padding: 3rem 3.5rem 3rem 0;
  }

  @media (min-width: $breakpoint-desktop + 1px) {
    padding: 6rem 6.5rem 6rem 0;
  }

  @media (max-width: $breakpoint-tablet) {
    @include make-container();  
  }
}

.home__banner-image {
  @media (min-width: $breakpoint-tablet + 1px) {
    flex: 1 1 auto;
    margin-right: calc(-50vw + 50%);
    object-fit: cover;
    object-position: center left;
    order: 2;
  }
}

.home__banner-title {
  background-color: $color-white;
  color: $color-purple;
  display: inline-block;  
  font-size: 40px;
  font-weight: $font-weight-black;
  line-height: 1;
  margin-bottom: 3rem;
  padding: 1.3rem 2.5rem;
  position: relative;
  width: auto;  

  @supports (clip-path: polygon(0 0, 100% 8px, 100% 100%, 0% 100%)) {
    clip-path: polygon(0 0, 100% 8px, 100% 100%, 0% 100%);  
    padding-top: 2.7rem;
  }

  @media (max-width: $breakpoint-desktop) {
    font-size: calc(24px + (40 - 22) * ((100vw - #{$breakpoint-mobile-landscape}) / (#{$breakpoint-desktop-raw} - #{$breakpoint-mobile-landscape-raw})));
    line-height: 1.08333;
    margin-bottom: 2rem;    
  }

  @media (max-width: $breakpoint-tablet) {
    margin-top: -3.6rem;
  }

  @media (max-width: $breakpoint-mobile-landscape) {
    font-size: 24px;
    margin-left: -$gutter-side-mobile;
    padding: 2.2rem 2.5rem 1.6rem;   

    @supports (clip-path: polygon(0 0, 100% 8px, 100% 100%, 0% 100%)) {
      padding-top: 2.2rem;
    }
  }  

  @media (min-width: $breakpoint-tablet + 1px) {
    white-space: nowrap;
  }
}

.home__banner-break {
  @media (min-width: $breakpoint-mobile-landscape + 1px) {
    display: none;
  }
}

.home__banner-text {
  @include rte-base();
}

// main
.home__main {
  padding-bottom: 4rem;
  @include clearfix();

  @media (max-width: $breakpoint-tablet) {
    padding-bottom: 0.6rem;
  }
}

.home__login {
  @media (min-width: $breakpoint-tablet+ 1px) {
    float: left;
    padding-right: 2rem;
    width: 50%;
  }

  @media (min-width: $breakpoint-notebook + 1px) {
    padding-right: 5.5rem;
  }
}

.home__new-content {
  @media (min-width: $breakpoint-tablet + 1px) {
    float: left;
    padding-left: 2rem;
    width: 50%;
  }

  @media (min-width: $breakpoint-notebook + 1px) {
    padding-left: 5.5rem;
  }  
}

// logged in
.home__member-home-title {
  @include element-invisible();
}