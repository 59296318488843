$plans-jump-link-padding: 2rem;

.plans {
  margin-top: -$plans-jump-link-padding;

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 5.4rem;
  }
}

.plans__section {
  padding-top: $plans-jump-link-padding;
  position: relative;
  margin-bottom: 3.4rem;

  svg {
    height: 4.5rem;
    left: 0;
    top: $plans-jump-link-padding;
    position: absolute;
    width: 3.9rem;
  }

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 5.6rem;
    padding-left: 13.9rem;

    svg {
      height: 8.8rem;
      width: 9rem;
    }
  }
}

.plans__section-title {
  border-bottom: 1px solid $color-purple;
  padding-left: 5.7rem;
  padding-top: 1.1rem;
  padding-bottom: 2.9rem;
  margin-bottom: 1.9rem;
  margin-top: 0;
  @include heading-secondary;

  @media (min-width: $breakpoint-notebook) {
    border-bottom: 0;
    margin-bottom: 3.5rem;
    padding-top: 2.4rem;
    padding-left: 0;
    padding-bottom: 0;
  }
}

.plans__section-group {
  margin-bottom: 3rem;

  @media (min-width: $breakpoint-notebook) {
    border-left: 1px solid $color-purple;
    margin-bottom: 0;
    margin-top: 4.5rem;
    padding-left: 3rem;
  }
}

.plans__section-group-title {
  @include heading-tertiary;
  margin-top: 0;
  margin-bottom: 2rem;

  @media (min-width: $breakpoint-notebook) {
    padding-top: 3px;
  }
}

.plans__section-items {
  list-style: none;
  margin: 0;
  padding: 0;
  
  @media (min-width: $breakpoint-notebook) {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.plans__section-item-wrapper {
  padding: 0;
  @include font-size(18, 1.66667);

  @media (min-width: $breakpoint-notebook) {
    flex: 0 0 auto;
  }  
}

.plans__section-item {
  margin: 0 0 2rem;

  @media (min-width: $breakpoint-notebook) {
    margin-right: 4.5rem;

    .plans__section-group & {
      margin-bottom: 0;
    }
  }
}

.plans__section-item-label {
  color: $color-charcoal;
  font-weight: $font-weight-bold;
}

.plans__section-item-value {
  margin-left: 0;

  a {
    display: inline-block;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    margin-top: -0.7rem;
    margin-bottom: -0.7rem;
  }
}

.plans__empty-text {
  @include rte-base();
}