.styleguide__table-header {
  color: rgb(118, 118, 118);
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 12px;
  line-height: 1.333;
  padding: 0 2rem 1rem;
  text-transform: uppercase;
  text-align: left;            
}

.styleguide__table-header--row {
  padding-bottom: 0;
  width: 10rem;
  text-align: right;
}

.styleguide__table {
  margin-bottom: 3rem;
  width: 100%;
}

.styleguide__table-cell {
  padding: 1rem 2rem;
  vertical-align: top;
} 