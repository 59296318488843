.banner {
  margin-bottom: 3rem;  

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 7rem;
  }  
}


.banner--login {
  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 3rem;
  }
}