$card-max-width: 42rem;
$card-min-height: 26.6rem;

.id-card {
  background-color: $color-pearl;
  padding: 3rem 0 0;

  @media (min-width: $breakpoint-desktop) {
    padding: 5rem 0 7.4rem;
  }
}

.id-card__at-heading {
  @include element-invisible();
}

.id-card--admin {
  background-color: $color-white;
}

.id-card__title {
  @include element-invisible();
}

.id-card__no-membership {
  padding: 5rem 0;
}

// global
.id-card__card-label {
  color: $color-charcoal;
  font-weight: $font-weight-black;
  margin-bottom: 1.5rem;
  margin-top: 0;
  text-align: center;
  text-transform: uppercase;
  @include font-size(14, 1.071);

  @media (min-width: $breakpoint-desktop) {
    flex: 0 0 auto;
  }
}

.id-card__label {
  font-family: $font-fira-extra-condensed;
  font-weight: $font-weight-light;
  margin-left: 0;
  @include font-size(20, 1.05);
}

.id-card__label--title {
  letter-spacing: -0.25px;
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
}

.id-card__value {
  font-family: $font-fira-extra-condensed;
  font-weight: $font-weight-semibold;
  @include font-size(20, 1.05);
}

.id-card__help-now-text {
  font-family: $font-fira-extra-condensed;
  font-weight: $font-weight-light;
  @include font-size(20, 1.05);

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.id-card__help-now-label {
  font-family: $font-fira-extra-condensed;
  font-weight: $font-weight-semibold;
  @include font-size(20, 1.05);  
}

.id-card__widow {
  white-space: nowrap;
}

.id-card__sublabel {
  display: inline-block;
  position: relative;
  top: 2px;
  vertical-align: bottom;
  @include font-size(12, 1.6667);
}

.id-card__inner {
  @media (min-width: $breakpoint-desktop) {
    display: flex;
    justify-content: center;
  }
}

// front
.id-card__group--front {
  @media (min-width: $breakpoint-desktop) {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-right: 4rem;
  }  
}

.id-card__card-front {
  background-color: $color-blue-8;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.5);
  color: $color-white;  
  display: flex;
  flex-direction: column;  
  margin-bottom: 3.1rem; 

  @media (min-width: $breakpoint-mobile-landscape) {
    margin-left: auto;
    margin-right: auto;
    min-height: $card-min-height;
    width: $card-max-width;
  }

  @media (min-width: $breakpoint-desktop) {
    flex: 1 1 auto;
    margin-bottom: 0;
  }  
}

.id-card__card-front-primary {
  padding: 1.2rem 1.2rem 1rem;
}

.id-card__value--name {
  margin-left: 0;
  text-transform: uppercase;
}

.id-card__name-group {
  margin-top: 0;
  margin-bottom: 0;
}

.id-card__name-date-group {
  margin-bottom: 1rem;
  position: relative;
}

.id-card__date {
  font-family: $font-fira-extra-condensed;
  font-weight: $font-weight-regular;
  position: absolute;
  right: 0rem;
  text-transform: uppercase;
  top: -0.6rem;
  @include font-size(14, 1.7857);
}

.id-card__card-front-primary-info-wrapper {
  @include clearfix();
}

.id-card__card-front-primary-info {
  margin: 0;
}

.id-card__label--member-id {
  float: left;
}

.id-card__value--member-id {
  float: left;
  margin-left: 5px;
}

.id-card__label--language-id {
  clear: left;
  float: left;

  @media (min-width: $breakpoint-mobile-landscape) {
    clear: none;
    margin-left: 0.9rem;
  }
}

.id-card__value--language-id {
  float: left;
  margin-left: 5px;
}

.id-card__card-front-secondary {
  flex: 1 1 auto;
  padding: 1rem 1.2rem 1.6rem;
  position: relative;

  a {
    color: $color-white;
    font-weight: $font-weight-light;

    &:hover {
      color: $color-white;
    }

    &:focus {
      color: $color-charcoal;;
    }
  }

  &:before {
    background-color: $color-yellow;
    border-radius: 1px;
    content: "";
    height: 2px;
    left: 1.2rem;
    position: absolute;
    right: 1.2rem;
    top: 0;
  }

  @media (min-width: $breakpoint-mobile-landscape) {
    padding-bottom: 1rem;
    @include clearfix();
  }
}

.id-card__card-front-footer {
  border-radius: 0 0 2px 2px;
  background-color: $color-white;
  color: $color-blue-8;
  padding: 1.7rem 1.2rem 1.3rem;

  @media (min-width: $breakpoint-mobile-landscape) {
    padding-bottom: 1.1rem;
    padding-right: 11.5rem;
    position: relative;
  }
}

.id-card__card-site-title {
  font-weight: $font-weight-bold;
  @include font-size(21, 1);
}

.id-card__card-site-info {
  font-family: $font-fira-extra-condensed;
  font-weight: $font-weight-light;
  margin-bottom: 1.1rem;
  @include font-size(21, 0.95238);

  @media (min-width: $breakpoint-mobile-landscape) {
    margin-bottom: 0;
  }

  a {
    color: $color-blue-8;
    font-family: $font-fira-extra-condensed;
    font-weight: $font-weight-light;   
    position: relative; 
  }
}

.id-card__card-logo {
  text-align: right;

  @media (min-width: $breakpoint-mobile-landscape) {
    position: absolute;
    right: 1.5rem;
    top: 1.9rem;
  }
}

.id-card__card-logo-image {
  width: 9.2rem;
  vertical-align: top;
}

// back
.id-card__group--back {
  @media (min-width: $breakpoint-desktop) {
    display: flex;
    flex-direction: column;    
    margin-left: 4rem;
  }  
}

.id-card__card-back {
  background-color: $color-blue-8;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,0.5);  
  color: $color-white;
  list-style: none;
  padding: 1.3rem 1.2rem 0.1rem;
  margin: 0 0 3.6rem;

  a {
    color: $color-white;
    font-weight: $font-weight-light;

    &:hover {
      color: $color-white;
    }

    &:focus {
      color: $color-charcoal;;
    }    
  } 

  @media (min-width: $breakpoint-mobile-landscape) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem 1.2rem 0rem;
    min-height: $card-min-height;
    width: $card-max-width;
  }

  @media (min-width: $breakpoint-desktop) {  
    flex: 1 1 auto;
    margin-bottom: 0;
  }
} 

.id-card__card-back-group {
  @media (min-width: $breakpoint-mobile-landscape) {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    width: calc(50% - 0.7rem);

    &:last-child {
      flex: 0 0 100%;
      width: 100%;
    }
  }  
}

.id-card__card-back-group-title {
  font-family: $font-fira-extra-condensed;
  font-weight: $font-weight-light;
  letter-spacing: -0.25px;
  margin-bottom: 2px;
  margin-top: 0;
  padding-right: 3.2rem;
  padding-bottom: 0.4rem;
  position: relative;
  @include font-size(20, 1);

  &:after {
    background-color: $color-yellow;
    border-radius: 0.5px;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    right: 3.2rem;
  }

  svg {
    position: absolute;
    right: 0;
    top: -2px;
    height: 2.7rem;
    width: 2.7rem;

    * {
      stroke: $color-yellow;
      stroke-width: 4px;
    }
  }

  @media (min-width: $breakpoint-mobile-landscape) {
    flex: 0 0 auto;
    margin-bottom: 2px;
  }
}

.id-card__card-back-subgroup {
  margin-bottom: 0.7rem;
  margin-top: 0;

  @media (min-width: $breakpoint-mobile-landscape) {
    flex: 0 0 auto; 
  }
}

.id-card__card-back-subgroup--rxbin {
  @include clearfix();
  
  @media (min-width: $breakpoint-mobile-landscape) {
    flex: 1 1 auto;
  }  
}

.id-card__card-back-subgroup--dhp {
  @media (min-width: $breakpoint-mobile-landscape) {
    flex: 1 1 auto;
  }    
}

.id-card__card-back-subgroup--mental-health {
  @media (min-width: $breakpoint-mobile-landscape) {
    margin-bottom: 0.3rem;
  }
}

.id-card__list-group {
  margin-top: 0;
  margin-bottom: 0;
}

.id-card__rx {
  font-family: $font-fira-extra-condensed;
  font-weight: $font-weight-regular;
  margin: 0;
  @include font-size(14, 1);
}

.id-card__rx-label {
  float: left;
  clear: left;
}

.id-card__rx-value {
  float: left;
  margin-left: 3px;
}

.id-card__value--mental-health,
.id-card__label--mental-health {
  display: inline-block;
}