.rte {
  max-width: 38em;
  @include rte-base;

  a {
    font-weight: $font-weight-bold;
    padding-top: 2px;

    &:hover {
      color: $color-charcoal;
    }
  }

  p,ol,ul {
    margin: 0;
  }

  ul {
    list-style: none;
    padding-left: 0;

    > li {
      padding-left: 3.6rem;
      position: relative;

      &:before {
        background-color: $color-charcoal;
        border: 2px solid $color-charcoal;;
        border-radius: 100%;
        content: "";
        height: 6px;
        position: absolute;
        left: 0;
        top: 0.9rem;
        width: 6px;
      }
    }
  }

  ol {
    list-style: none;
    padding-left: 0;
    counter-reset: ol-counter;

    > li {
      counter-increment: ol-counter;
      padding-left: 3.6rem;
      position: relative;

      &:before {
        content: counter(ol-counter) '.';
        left: 0;
        position: absolute;
      }
    }
  }

  ol,ul {
    ul > li {
      &:before {
        background-color: transparent;
        border: 2px solid $color-charcoal;;
      }
    }

    > li {
      > h3:first-child {
        padding-bottom: 6px;
        padding-top: 4px;
      }
    }

    > li:not(:first-child) {
      > h3:first-child {
        margin-top: 3rem;
      }      
    }    
  }

  h2 {
    @include heading-secondary;
    margin: 0;
    padding-bottom: 30px;

    @media (max-width: $breakpoint-mobile-landscape) {
      padding-bottom: 15px;
    }
  }

  h3 {
    @include heading-tertiary;
    margin: 0;
    padding-bottom: 30px;

    @media (max-width: $breakpoint-mobile-landscape) {
      padding-bottom: 15px;
    }    
  }

  h4 {
    @include heading-quaternary;
    margin: 0;
    padding-bottom: 30px;  

    @media (max-width: $breakpoint-mobile-landscape) {
      padding-bottom: 15px;
    }
  }

  h5 {
    @include heading-quinary;
    margin: 0;
    padding-bottom: 24px;
    
    @media (max-width: $breakpoint-mobile-landscape) {
      padding-bottom: 15px;
    }    
  }

  h6 {
    @include heading-senary;
    margin: 0;
    padding-bottom: 24px;

    @media (max-width: $breakpoint-mobile-landscape) {
      padding-bottom: 15px;
    }      
  }

  .ombuassets {
    margin: 0 0 $default-rte-margin;
  }

  .pull-left {
    @media (min-width: $breakpoint-tablet) {
      float: left;
      margin-right: $default-rte-margin;
      max-width: 50%;
    }
  }

  .pull-right {
    @media (min-width: $breakpoint-tablet) {
      float: right;
      margin-left: $default-rte-margin;
      max-width: 50%;
    }
  }  

  hr {
    border-bottom: none;
    border-top: 1px solid $color-graphite;
    margin: 6rem 0;

    @media (max-width: $breakpoint-mobile-landscape) {
      margin: 4.8rem 0;
    }
  }

  .p--diminished {
    font-size: 85%;
    font-weight: $font-weight-medium;
  }

  // margins
  p,ul,ol {
    & + p,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6,
    & + .button-primary,
    & + .ombuassets,
    & + .pull-left + p,
    & + .pull-left + h2,
    & + .pull-left + h3,
    & + .pull-left + h4,
    & + .pull-left + h5,
    & + .pull-left + h6,
    & + .pull-right + p,
    & + .pull-right + h2,
    & + .pull-right + h3,
    & + .pull-right + h4,
    & + .pull-right + h5,
    & + .pull-right + h6 {
      margin-top: $default-rte-margin;

      @media (max-width: $breakpoint-notebook) {
        margin-top: 3.36vw;
      }   

      @media (max-width: $breakpoint-mobile-landscape) {
        margin-top: 22px;
      }
    }
  }
}


