.modal__content {
  background-color: $color-white;
  box-shadow: 0 3px 10px 0 rgba(0,0,0,0.1);
  border-radius: 0;
  border: none;
  padding: 2rem;
}

.modal__title {
  @include heading-secondary();
  flex: 1 1 auto;
  margin-top: 0;
  margin-bottom: 0;
}

.modal__header {
  display: flex;
  margin-bottom: 3rem;
}

.modal__close {
  background: none;
  border: none;
  border-radius: 2px;
  flex: 0 0 auto;
  font-size: 4rem;
  height: $default-touch-target;
  line-height: $default-touch-target;
  margin-right: -1rem;
  outline: 0;
  padding: 0;
  text-align: center;
  width: $default-touch-target;
  @include custom-focus();

  @media (max-width: $breakpoint-mobile-landscape) {
    margin-top: -.75rem;
  }
}

.modal__footer {
  align-items: center;
  justify-content: flex-start;
  display: flex;
  margin-top: 3rem;
}

.modal__cancel {
  background: none;
  border: none;
  border-radius: 2px;
  color: $color-charcoal;
  margin-right: 1.5rem;
  outline: 0;
  padding: 0;
  font-weight: $font-weight-bold;
  text-decoration: underline;
  @include custom-focus();

  &:hover {
    color: $color-charcoal;
  }
}

.modal__confirm {
  @include button-primary();
}