.alerts-banner {
  color: $color-obsidian;
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media (min-width: $breakpoint-notebook) {
    padding-bottom: 2.6rem;
  }  
}

.alerts-banner--warning {
  background-color: $color-yellow;
}

.alerts-banner--error {
  background-color: $color-orange-plus-20;
}

.alerts-banner--success {
  background-color: $color-green-plus-40;
}

.alerts-banner__inner {
  display: flex;
  position: relative;  

  svg {
    align-self: flex-start;
    flex: 0 0 auto;
    height: auto;
    margin-right: 1.3rem;
    width: 3.4rem;
  }

  @media (min-width: $breakpoint-notebook) {
    svg {
      width: 6.8rem;
    }
  }
}

.alerts-banner__inner-message {
  align-self: center;
}

.alerts-banner__title {
  font-weight: $font-weight-black;
  margin-bottom: 2rem;
  margin-top: 0;
  @include font-size(24, 1.25);

  @media (min-width: $breakpoint-notebook) {
    margin-bottom: 0.3rem;    
  }
}

.alerts-banner__content {

  a {
    color: $color-obsidian;
  }

  .errorlist {
    .form__errors--field {
      font-style: normal;
      @include rte-base();
    }
  }

  &.rte {
    max-width: 100%;
  }
}