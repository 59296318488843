.cta-link {
  background-image: url('../core/images/caret.svg');
  background-repeat: no-repeat;
  background-position: left 40%;
  background-size: 11px;
  color: $color-blue-10;
  display: inline-block;
  font-weight: $font-weight-black;
  padding-left: 1.6rem;

  &:hover {
    background-image: url('../core/images/caret--charcoal.svg');
    color: $color-charcoal;
  }
}