/**
 * Base
 *
 * Base styles include HTML element types only, no classes or IDs
 */

html {
  font-size: $font-size-root-px;
}

body {
  color: $color-charcoal;
  font-family: $font-sailec;
  font-weight: $font-weight-regular;
  overflow-wrap: break-word;
  @include font-size($font-size-base, $line-height-base);
}

p {
  margin-top: $line-height-base + 0em;
  margin-bottom: $line-height-base + 0em;
}

a {
  border-radius: 2px;
  color: $color-charcoal;
  font-weight: $font-weight-bold;
  outline: 0;
  @include custom-focus();

  &:hover {
    color: $color-charcoal;
  }  
}

h1 {
  font-weight: $font-weight-black;
  margin-bottom: 0.6em;
  margin-top: 0;
  @include font-size(75, 0.95);
}

h2 {
  font-weight: $font-weight-black;
  margin-top: 1em;
  margin-bottom: 1em;
  @include font-size(40, 1.125);
}

h3 {
  font-weight: $font-weight-bold;
  margin-top: 4rem;
  margin-bottom: 3rem;
  @include font-size(24, 1);
}

h4 {
  font-weight: $font-weight-black;
  @include font-size(18, 1);
}

h5 {
  font-weight: $font-weight-black;
  text-transform: uppercase;
  @include font-size(24, 1.25);
}

h6 {
  font-weight: $font-weight-bold;
  @include font-size(12.06, 1);
}

img,video,iframe {
  height: auto;
  max-width: 100%;
}

article {
  margin-bottom: $default-margin;
}

figure {
  margin: 0;
}

figcaption {
  margin-top: 1rem;
}

input[type="text"],
input[type="password"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="date"],
select,
textarea {
  background-color: $color-pearl;
  border: 1px solid $color-charcoal;
  border-radius: 2px;
  color: $color-mineshaft;
  font: $font-sailec;
  font-weight: $font-weight-medium;
  padding: 0.94rem 1rem 0.74rem;
  width: 100%;
  @include font-size(18, 1.4);
  @include custom-form-focus();

  &[aria-invalid="true"] {
    background-color: $color-error-bg;
  }

  &:focus,
  &.focus {
    background-color: $color-light-blue-plus-18;
    outline: none;
  }
}

textarea {
  transition: box-shadow $transition-duration-default ease;;
  resize: vertical;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('../core/images/select.svg');
  background-position: calc(100% - 1.4rem) center;
  background-repeat: no-repeat;
  background-size: 20px 12px;
  padding-right: 4rem;

  &::-ms-expand {
    display: none;
  }
}

label {
  @include custom-label();
}

legend {
  @include custom-label();
}

abbr[title] {
  cursor: help;
  text-decoration: none;
}