.link-primary {
  display: inline-block;
  margin: -0.3rem;
  padding: 0.3rem;
  text-decoration: none;
}

.link-primary__inner {
  background-color: $color-purple-4;
  border: 3px solid transparent;
  color: $color-white;
  display: inline-block;
  font-weight: $font-weight-black;
  padding: 1.2rem 2.2rem;
  transition: all $transition-duration-default ease;
  @include font-size(24, 1);

  @supports (clip-path: polygon(0 0, 100% 10.75%, 100% 100%, 0% 100%)) {
    clip-path: polygon(0 0, 100% 10.75%, 100% 100%, 0% 100%);  
    padding-top: 2.2rem;
  }

  @media (min-width: $breakpoint-notebook) {
    @include font-size(40, 1);
  }
}

a.link-primary__inner  {
  &:focus {
    border-color: $color-blue;
  }

  &:hover {
    background-color: $color-purple-4-hover;
  }  
}