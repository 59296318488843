// Accessibility Links
// -----------------------------------------------------------------------------
.accessibility-links__skip-to-main {
  color: $color-white;
  display: block;
  height: 0;
  text-indent: -9999px;
  transition: none;
  width: 0;

  &:focus,
  &:active,
  &:hover {
    color: $color-charcoal;
    display: inline-block;
    height: auto;
    text-indent: 0;
    width: auto;
  }
}