.sitemap {
  margin-bottom: $default-margin;
}

.sitemap__list {
  @include rte-base;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 1.5rem;
}

.sitemap__list-item {
   padding-left: 2.1rem;
}

.sitemap__link {
  font-weight: $font-weight-bold;
  padding-top: 2px;  
}

.sitemap__submenu {
  padding-left: 1.5rem;
}